@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700;900&display=swap");

a {
  text-decoration: none;
}
body {
  font-family: "Poppins";
}

input,
select {
  color: #4c0c61;
  width: 100%;
}
input[type="checkbox"] {
  accent-color: #4c0c61;
}
input,
select {
  border: 1px solid #dbdbdb;
  padding: 4px 10px;
  border-radius: 4px;
  outline: none;
}
input:hover,
input:focus {
  border: 1px solid #8815ad;
}

.btn-custom-contained {
  padding: 15px 25px;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  &.btn-primary {
    border: 1px solid #8815ae;
    background-color: #8815ae;
    color: #fff;
    &:hover {
      color: white;
      background-color: #5d1177;
    }
  }
  &.btn-secondary {
    border: none;
    background-color: #ffffff;
    color: #4c0c61;
    &:hover {
      color: white;
      background-color: #5e117783;
    }
  }
}

h3 {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -0.02em;
  @media only screen and (max-width: 554px) {
    font-size: 35px;
    line-height: 52px;
  }
}

h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 20px;
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
  @media only screen and (max-width: 554px) {
    text-align: center;
    font-size: 30px;
  }
}

h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.02em;
  @media only screen and (max-width: 554px) {
    text-align: center;
    font-size: 18px;
  }
}

.InfiniteCarouselSlide img {
  width: 50%;
}

h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
  @media only screen and (max-width: 554px) {
    font-size: 16px;
  }
}
